import { Union, Record } from "../fable_modules/fable-library.4.1.4/Types.js";
import { tuple_type, list_type, union_type, class_type, option_type, record_type, string_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { string, object } from "../fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { object as object_1 } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.4.1.4/Seq.js";
import { ofArray } from "../fable_modules/fable-library.4.1.4/List.js";
import { Stallzeit_$reflection } from "../Angebote/Types.fs.js";

export class GitHubApi_GetContentResponse extends Record {
    constructor(Url, Content, Sha) {
        super();
        this.Url = Url;
        this.Content = Content;
        this.Sha = Sha;
    }
}

export function GitHubApi_GetContentResponse_$reflection() {
    return record_type("Administration.Types.GitHubApi.GetContentResponse", [], GitHubApi_GetContentResponse, () => [["Url", string_type], ["Content", string_type], ["Sha", string_type]]);
}

export const GitHubApi_GetContentResponseModule_decoder = (path_3) => ((v) => object((get$) => {
    let objectArg, objectArg_1, objectArg_2;
    return new GitHubApi_GetContentResponse((objectArg = get$.Required, objectArg.Field("url", string)), (objectArg_1 = get$.Required, objectArg_1.Field("content", string)), (objectArg_2 = get$.Required, objectArg_2.Field("sha", string)));
}, path_3, v));

export class GitHubApi_SetContentRequest extends Record {
    constructor(Message, Content, Sha, Branch) {
        super();
        this.Message = Message;
        this.Content = Content;
        this.Sha = Sha;
        this.Branch = Branch;
    }
}

export function GitHubApi_SetContentRequest_$reflection() {
    return record_type("Administration.Types.GitHubApi.SetContentRequest", [], GitHubApi_SetContentRequest, () => [["Message", string_type], ["Content", string_type], ["Sha", option_type(string_type)], ["Branch", string_type]]);
}

export function GitHubApi_SetContentRequestModule_encode(v) {
    return object_1(toList(delay(() => append(singleton(["message", v.Message]), delay(() => append(singleton(["content", v.Content]), delay(() => {
        let matchValue;
        return append((matchValue = v.Sha, (matchValue == null) ? (empty()) : singleton(["sha", matchValue])), delay(() => singleton(["branch", v.Branch])));
    })))))));
}

export class GitHubApi_SetContentResponse extends Record {
    constructor(Sha) {
        super();
        this.Sha = Sha;
    }
}

export function GitHubApi_SetContentResponse_$reflection() {
    return record_type("Administration.Types.GitHubApi.SetContentResponse", [], GitHubApi_SetContentResponse, () => [["Sha", string_type]]);
}

export const GitHubApi_SetContentResponseModule_decoder = (path_1) => ((v) => object((get$) => {
    let objectArg;
    return new GitHubApi_SetContentResponse((objectArg = get$.Required, objectArg.At(ofArray(["content", "sha"]), string)));
}, path_1, v));

export class LoadStallzeitenError extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["HttpError", "ParseError"];
    }
}

export function LoadStallzeitenError_$reflection() {
    return union_type("Administration.Types.LoadStallzeitenError", [], LoadStallzeitenError, () => [[["Item", class_type("System.Exception")]], [["Item", string_type]]]);
}

export class SaveStallzeitenError extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["HttpError"];
    }
}

export function SaveStallzeitenError_$reflection() {
    return union_type("Administration.Types.SaveStallzeitenError", [], SaveStallzeitenError, () => [[["Item", class_type("System.Exception")]]]);
}

export class StallzeitTimestampValue extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Invalid", "Valid"];
    }
}

export function StallzeitTimestampValue_$reflection() {
    return union_type("Administration.Types.StallzeitTimestampValue", [], StallzeitTimestampValue, () => [[["Item", string_type]], [["Item", class_type("System.DateTime")]]]);
}

export class StallzeitValue extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Timestamp", "InfoText"];
    }
}

export function StallzeitValue_$reflection() {
    return union_type("Administration.Types.StallzeitValue", [], StallzeitValue, () => [[["Item", StallzeitTimestampValue_$reflection()]], [["Item", string_type]]]);
}

export class LocalStallzeit extends Record {
    constructor(Id, Value) {
        super();
        this.Id = Id;
        this.Value = Value;
    }
}

export function LocalStallzeit_$reflection() {
    return record_type("Administration.Types.LocalStallzeit", [], LocalStallzeit, () => [["Id", string_type], ["Value", StallzeitValue_$reflection()]]);
}

export class LoadedStallzeiten extends Record {
    constructor(Version, FileUrl, Stallzeiten) {
        super();
        this.Version = Version;
        this.FileUrl = FileUrl;
        this.Stallzeiten = Stallzeiten;
    }
}

export function LoadedStallzeiten_$reflection() {
    return record_type("Administration.Types.LoadedStallzeiten", [], LoadedStallzeiten, () => [["Version", string_type], ["FileUrl", string_type], ["Stallzeiten", list_type(Stallzeit_$reflection())]]);
}

export class Stallzeiten extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NotLoaded", "Loading", "Loaded", "LoadError"];
    }
}

export function Stallzeiten_$reflection() {
    return union_type("Administration.Types.Stallzeiten", [], Stallzeiten, () => [[], [], [["Item", LoadedStallzeiten_$reflection()]], [["Item", LoadStallzeitenError_$reflection()]]]);
}

export class Model extends Record {
    constructor(GitHubAccessToken, RemoteStallzeiten, LocalStallzeiten) {
        super();
        this.GitHubAccessToken = GitHubAccessToken;
        this.RemoteStallzeiten = RemoteStallzeiten;
        this.LocalStallzeiten = LocalStallzeiten;
    }
}

export function Model_$reflection() {
    return record_type("Administration.Types.Model", [], Model, () => [["GitHubAccessToken", string_type], ["RemoteStallzeiten", Stallzeiten_$reflection()], ["LocalStallzeiten", list_type(LocalStallzeit_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoadStallzeiten", "LoadStallzeitenSuccess", "LoadStallzeitenError", "UpdateGitHubAccessToken", "Login", "UpdateStallzeit", "AddStallzeitTimestamp", "AddStallzeitInfoText", "RemoveStallzeit", "SaveStallzeiten", "SaveStallzeitenSuccess", "SaveStallzeitenError"];
    }
}

export function Msg_$reflection() {
    return union_type("Administration.Types.Msg", [], Msg, () => [[], [["Item", GitHubApi_GetContentResponse_$reflection()]], [["Item", LoadStallzeitenError_$reflection()]], [["Item", string_type]], [], [["Item", tuple_type(string_type, StallzeitValue_$reflection())]], [], [], [["Item", string_type]], [], [["Item", GitHubApi_SetContentResponse_$reflection()]], [["Item", SaveStallzeitenError_$reflection()]]]);
}

