import { Union } from "../../fable-library.4.1.4/Types.js";
import { union_type, list_type, class_type, string_type } from "../../fable-library.4.1.4/Reflection.js";
import { Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.4.1.4/MapUtil.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-16x16", "is-24x24", "is-32x32", "is-48x48", "is-64x64", "is-96x96", "is-128x128", "is-square", "is-1by1", "is-5by4", "is-4by3", "is-3by2", "is-5by3", "is-16by9", "is-2by1", "is-3by1", "is-4by5", "is-3by4", "is-2by3", "is-3by5", "is-9by16", "is-1by2", "is-1by3", "is-fullwidth", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Image.Option", [], Option, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <figure class="image"></figure>
 */
export function image(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 25:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 24:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 26:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "image"), (props_1, children_1) => react.createElement("figure", keyValueList(props_1, 1), ...children_1), children);
}

