import { tryParse, minValue } from "../fable_modules/fable-library.4.1.4/Date.js";
import { FSharpRef } from "../fable_modules/fable-library.4.1.4/Types.js";
import { LoadStallzeitenError, Msg, Model, Stallzeiten, Stallzeit } from "./Types.fs.js";
import { map, toList } from "../fable_modules/fable-library.4.1.4/Seq.js";
import { split } from "../fable_modules/fable-library.4.1.4/String.js";
import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { fetch$ } from "../fable_modules/Fable.Fetch.2.6.0/Fetch.fs.js";
import { empty } from "../fable_modules/fable-library.4.1.4/List.js";

function parseStallzeit(text) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse(text, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return new Stallzeit(0, [matchValue[1]]);
    }
    else {
        return new Stallzeit(1, [text]);
    }
}

function parseStallzeiten(text) {
    return toList(map(parseStallzeit, split(text, ["\n", "\r"], void 0, 1)));
}

export const init = [new Model(new Stallzeiten(0, [])), Cmd_OfPromise_either(() => {
    let pr_1;
    const pr = fetch$("api/stallzeiten", empty());
    pr_1 = (pr.then((r) => r.text()));
    return pr_1.then(parseStallzeiten);
}, void 0, (arg) => (new Msg(0, [arg])), (arg_3) => (new Msg(1, [new LoadStallzeitenError(arg_3)])))];

export function update(msg, model) {
    if (msg.tag === 1) {
        return [new Model(new Stallzeiten(2, [msg.fields[0]])), empty()];
    }
    else {
        return [new Model(new Stallzeiten(1, [msg.fields[0]])), empty()];
    }
}

