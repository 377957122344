import { unwrap, map as map_1, defaultArg, some, ofNullable } from "../fable_modules/fable-library.4.1.4/Option.js";
import { list as list_5, fromString } from "../fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { uncurry2 } from "../fable_modules/fable-library.4.1.4/Util.js";
import { Stallzeit, StallzeitModule_encode, StallzeitModule_decoder } from "../Angebote/Types.fs.js";
import { GitHubApi_GetContentResponse_$reflection, GitHubApi_GetContentResponseModule_decoder, SaveStallzeitenError, GitHubApi_SetContentResponse_$reflection, GitHubApi_SetContentResponseModule_decoder, GitHubApi_SetContentRequestModule_encode, GitHubApi_SetContentRequest, Model, LocalStallzeit, StallzeitTimestampValue, StallzeitValue, Stallzeiten, LoadedStallzeiten, Msg, LoadStallzeitenError } from "./Types.fs.js";
import { cons, ofArray, choose, filter, singleton, append, empty, map } from "../fable_modules/fable-library.4.1.4/List.js";
import { newGuid } from "../fable_modules/fable-library.4.1.4/Guid.js";
import { today, addDays, addHours } from "../fable_modules/fable-library.4.1.4/Date.js";
import { list as list_6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_none, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library.4.1.4/String.js";
import { Types_RequestProperties_$reflection, Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.6.0/Fetch.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.1.4/Choice.js";
import { Helper_message, Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/./Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.4.1.4/MapUtil.js";
import { toString as toString_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.10.2.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.10.2.0/./Encode.fs.js";
import { list_type, obj_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { Auto_generateBoxedDecoderCached_Z6670B51 } from "../fable_modules/Thoth.Json.10.2.0/./Decode.fs.js";
import { fromString as fromString_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.10.2.0/Decode.fs.js";
import { Toastr_error, Toastr_message, Toastr_title, Toastr_showCloseButton, Toastr_success } from "../fable_modules/Elmish.Toastr.2.1.0/Elmish.Toastr.fs.js";

function tryGetGitHubAccessToken() {
    return ofNullable(localStorage.getItem("GITHUB_ACCESS_TOKEN"));
}

function setGitHubAccessToken(value) {
    localStorage.setItem("GITHUB_ACCESS_TOKEN", value);
}

export const stallzeitenRemoteUrl = "https://api.github.com/repos/enserhof/website/contents/src/Server/api/stallzeiten?ref=main";

export function update(msg_mut, model_mut) {
    let copyOfStruct_1, copyOfStruct_4;
    update:
    while (true) {
        const msg = msg_mut, model = model_mut;
        switch (msg.tag) {
            case 1: {
                const _arg_3 = fromString((path, value_2) => list_5(uncurry2(StallzeitModule_decoder), path, value_2), window.atob(msg.fields[0].Content));
                if (_arg_3.tag === 1) {
                    msg_mut = (new Msg(2, [new LoadStallzeitenError(1, [_arg_3.fields[0]])]));
                    model_mut = model;
                    continue update;
                }
                else {
                    const stallzeiten = _arg_3.fields[0];
                    return [new Model(model.GitHubAccessToken, new Stallzeiten(2, [new LoadedStallzeiten(msg.fields[0].Sha, msg.fields[0].Url, stallzeiten)]), map((timestamp) => {
                        let copyOfStruct;
                        const stallzeitValue = (timestamp.tag === 1) ? (new StallzeitValue(1, [timestamp.fields[0]])) : (new StallzeitValue(0, [new StallzeitTimestampValue(1, [timestamp.fields[0]])]));
                        return new LocalStallzeit((copyOfStruct = newGuid(), copyOfStruct), stallzeitValue);
                    }, stallzeiten)), empty()];
                }
            }
            case 2:
                if (msg.fields[0].tag === 1) {
                    console.error(some("Error while parsing Stallzeiten: "), msg.fields[0].fields[0]);
                    return [new Model(model.GitHubAccessToken, new Stallzeiten(3, [msg.fields[0]]), empty()), empty()];
                }
                else {
                    console.error(some("Error while loading Stallzeiten: "), msg.fields[0].fields[0]);
                    return [new Model(model.GitHubAccessToken, new Stallzeiten(3, [msg.fields[0]]), empty()), empty()];
                }
            case 3:
                return [new Model(msg.fields[0], model.RemoteStallzeiten, model.LocalStallzeiten), empty()];
            case 4: {
                setGitHubAccessToken(model.GitHubAccessToken);
                msg_mut = (new Msg(0, []));
                model_mut = model;
                continue update;
            }
            case 5:
                return [new Model(model.GitHubAccessToken, model.RemoteStallzeiten, map((stallzeit) => {
                    if (msg.fields[0][0] === stallzeit.Id) {
                        return new LocalStallzeit(msg.fields[0][0], msg.fields[0][1]);
                    }
                    else {
                        return stallzeit;
                    }
                }, model.LocalStallzeiten)), empty()];
            case 6:
                return [new Model(model.GitHubAccessToken, model.RemoteStallzeiten, append(model.LocalStallzeiten, singleton(new LocalStallzeit((copyOfStruct_1 = newGuid(), copyOfStruct_1), new StallzeitValue(0, [new StallzeitTimestampValue(1, [addHours(addDays(today(), 1), 8.5)])]))))), empty()];
            case 7:
                return [new Model(model.GitHubAccessToken, model.RemoteStallzeiten, append(model.LocalStallzeiten, singleton(new LocalStallzeit((copyOfStruct_4 = newGuid(), copyOfStruct_4), new StallzeitValue(1, [""]))))), empty()];
            case 8:
                return [new Model(model.GitHubAccessToken, model.RemoteStallzeiten, filter((t) => (t.Id !== msg.fields[0]), model.LocalStallzeiten)), empty()];
            case 9: {
                const save = (url_2, version) => {
                    let arg_11;
                    const body_2 = new GitHubApi_SetContentRequest("Update Stallzeiten", (arg_11 = toString(0, list_6(map(StallzeitModule_encode, choose((item) => {
                        const matchValue_1 = item.Value;
                        if (matchValue_1.tag === 1) {
                            return new Stallzeit(1, [matchValue_1.fields[0]]);
                        }
                        else if (matchValue_1.fields[0].tag === 0) {
                            return void 0;
                        }
                        else {
                            return new Stallzeit(0, [matchValue_1.fields[0].fields[0]]);
                        }
                    }, model.LocalStallzeiten)))), window.btoa(arg_11)), version, "main");
                    return [model, Cmd_OfPromise_either(() => {
                        const properties_5 = singleton(new Types_RequestProperties(1, [{
                            Authorization: toText(printf("Bearer %s"))(model.GitHubAccessToken),
                        }]));
                        const data_4 = some(GitHubApi_SetContentRequestModule_encode(body_2));
                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let data_6, caseStrategy_9, extra_9;
                            return ((data_6 = data_4, (caseStrategy_9 = void 0, (extra_9 = void 0, (() => {
                                let properties_9;
                                try {
                                    const properties_3_1 = Helper_withProperties(properties_5, (properties_9 = ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_6, empty()), 0)])]), defaultArg(map_1((data_1_2) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_9, extra_9)(data_1_2))]), properties_9), data_6), properties_9)));
                                    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helper_fetch(url_2, properties_3_1).then((_arg_4) => {
                                        let response_4, decoder_1_2;
                                        return ((response_4 = _arg_4, (decoder_1_2 = defaultArg(GitHubApi_SetContentResponseModule_decoder, Auto_generateBoxedDecoderCached_Z6670B51(GitHubApi_SetContentResponse_$reflection(), unwrap(caseStrategy_9), unwrap(extra_9))), PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (((response_4.ok) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (response_4.text().then((_arg_5) => {
                                            let matchValue_2;
                                            return Promise.resolve((matchValue_2 = fromString_1(uncurry2(decoder_1_2), _arg_5), (matchValue_2.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_2.fields[0]])])) : (new FSharpResult$2(0, [matchValue_2.fields[0]]))));
                                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_4])])))).then((_arg_1_2) => (Promise.resolve(_arg_1_2)))))))));
                                    }))));
                                    return pr_1.catch((arg_16) => (new FSharpResult$2(1, [new FetchError(3, [arg_16])])));
                                }
                                catch (exn_1) {
                                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn_1])])))));
                                }
                            })())))).then((_arg_6) => {
                                const result_3 = _arg_6;
                                let response_1_2;
                                if (result_3.tag === 1) {
                                    throw new Error(Helper_message(result_3.fields[0]));
                                }
                                else {
                                    response_1_2 = result_3.fields[0];
                                }
                                return Promise.resolve(response_1_2);
                            });
                        }));
                    }, void 0, (arg_17) => (new Msg(10, [arg_17])), (arg_20) => (new Msg(11, [new SaveStallzeitenError(arg_20)])))];
                };
                const matchValue_3 = model.RemoteStallzeiten;
                switch (matchValue_3.tag) {
                    case 2: {
                        const remoteStallzeiten = matchValue_3.fields[0];
                        return save(remoteStallzeiten.FileUrl, remoteStallzeiten.Version);
                    }
                    case 3:
                        return save(stallzeitenRemoteUrl, void 0);
                    default:
                        return [model, empty()];
                }
            }
            case 10: {
                const matchValue_4 = model.RemoteStallzeiten;
                if (matchValue_4.tag === 2) {
                    const stallzeiten_1 = matchValue_4.fields[0];
                    return [new Model(model.GitHubAccessToken, new Stallzeiten(2, [new LoadedStallzeiten(msg.fields[0].Sha, stallzeiten_1.FileUrl, stallzeiten_1.Stallzeiten)]), model.LocalStallzeiten), Toastr_success(Toastr_showCloseButton(Toastr_title("Stallzeiten speichern", Toastr_message("Stallzeiten wurden erfolgreich gespeichert"))))];
                }
                else {
                    return [model, empty()];
                }
            }
            case 11: {
                console.error(some("Error while saving Stallzeiten: "), msg.fields[0].fields[0]);
                return [model, Toastr_error(Toastr_showCloseButton(Toastr_title("Stallzeiten speichern", Toastr_message("Fehler beim Speichern der Stallzeiten"))))];
            }
            default:
                return [new Model(model.GitHubAccessToken, new Stallzeiten(1, []), model.LocalStallzeiten), Cmd_OfPromise_either(() => {
                    const properties = singleton(new Types_RequestProperties(1, [{
                        Authorization: toText(printf("Bearer %s"))(model.GitHubAccessToken),
                    }]));
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let data_1, caseStrategy_1, extra_1;
                        return ((data_1 = properties, (caseStrategy_1 = void 0, (extra_1 = void 0, (() => {
                            let properties_4;
                            try {
                                const properties_3 = Helper_withProperties(void 0, (properties_4 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(list_type(Types_RequestProperties_$reflection()), caseStrategy_1, extra_1)(data_1_1))]), properties_4), data_1), properties_4)));
                                const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helper_fetch(stallzeitenRemoteUrl, properties_3).then((_arg) => {
                                    let response_1, decoder_1_1;
                                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(GitHubApi_GetContentResponseModule_decoder, Auto_generateBoxedDecoderCached_Z6670B51(GitHubApi_GetContentResponse_$reflection(), unwrap(caseStrategy_1), unwrap(extra_1))), PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (response_1.text().then((_arg_1) => {
                                        let matchValue;
                                        return Promise.resolve((matchValue = fromString_1(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                }))));
                                return pr.catch((arg_4) => (new FSharpResult$2(1, [new FetchError(3, [arg_4])])));
                            }
                            catch (exn) {
                                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                            }
                        })())))).then((_arg_2) => {
                            const result_1 = _arg_2;
                            let response_1_1;
                            if (result_1.tag === 1) {
                                throw new Error(Helper_message(result_1.fields[0]));
                            }
                            else {
                                response_1_1 = result_1.fields[0];
                            }
                            return Promise.resolve(response_1_1);
                        });
                    }));
                }, void 0, (arg_5) => (new Msg(1, [arg_5])), (arg_8) => (new Msg(2, [new LoadStallzeitenError(0, [arg_8])])))];
        }
        break;
    }
}

export const init = (() => {
    const gitHubAccessToken = tryGetGitHubAccessToken();
    const model = new Model(defaultArg(gitHubAccessToken, ""), new Stallzeiten(0, []), empty());
    return (gitHubAccessToken == null) ? [model, Cmd_none()] : update(new Msg(0, []), model);
})();

