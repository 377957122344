import { Union } from "../../fable-library.4.1.4/Types.js";
import { union_type, string_type, list_type, class_type } from "../../fable-library.4.1.4/Reflection.js";
import { Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.4.1.4/MapUtil.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-fluid", "is-widescreen", "is-fullhd", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Container.Option", [], Option, () => [[], [], [], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <div class="container"></div>
 */
export function container(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 3:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 4:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 5:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "container"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

