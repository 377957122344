import { add, ofList, remove, empty } from "../fable_modules/fable-library.4.1.4/Set.js";
import { compare } from "../fable_modules/fable-library.4.1.4/Util.js";
import { allMenuItems, Model } from "./Types.fs.js";
import { empty as empty_1 } from "../fable_modules/fable-library.4.1.4/List.js";

export function init() {
    return [new Model(empty({
        Compare: compare,
    })), empty_1()];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1:
            return [new Model(remove(msg.fields[0], model.OpenMenus)), empty_1()];
        case 2:
            return [new Model(ofList(allMenuItems, {
                Compare: compare,
            })), empty_1()];
        default:
            return [new Model(add(msg.fields[0], model.OpenMenus)), empty_1()];
    }
}

