import { Record, Union } from "./fable_modules/fable-library.4.1.4/Types.js";
import { Page_$reflection } from "./Global.fs.js";
import { Model_$reflection as Model_$reflection_1, Msg_$reflection } from "./Angebote/Types.fs.js";
import { Model_$reflection as Model_$reflection_2, Msg_$reflection as Msg_$reflection_1 } from "./UeberDenHof/Types.fs.js";
import { Model_$reflection as Model_$reflection_3, Msg_$reflection as Msg_$reflection_2 } from "./Administration/Types.fs.js";
import { record_type, union_type } from "./fable_modules/fable-library.4.1.4/Reflection.js";

export class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ShowPage", "AngeboteMsg", "UeberDenHofMsg", "AdministrationMsg"];
    }
}

export function Message_$reflection() {
    return union_type("App.Types.Message", [], Message, () => [[["Item", Page_$reflection()]], [["Item", Msg_$reflection()]], [["Item", Msg_$reflection_1()]], [["Item", Msg_$reflection_2()]]]);
}

export class Model extends Record {
    constructor(CurrentPage, Angebote, UeberDenHof, Administration) {
        super();
        this.CurrentPage = CurrentPage;
        this.Angebote = Angebote;
        this.UeberDenHof = UeberDenHof;
        this.Administration = Administration;
    }
}

export function Model_$reflection() {
    return record_type("App.Types.Model", [], Model, () => [["CurrentPage", Page_$reflection()], ["Angebote", Model_$reflection_1()], ["UeberDenHof", Model_$reflection_2()], ["Administration", Model_$reflection_3()]]);
}

