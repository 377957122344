import { printf, toText } from "../fable_modules/fable-library.4.1.4/String.js";
import { HTMLAttr, CSSProp } from "../fable_modules/Fable.React.9.3.0/Fable.React.Props.fs.js";
import { append, singleton, ofArray } from "../fable_modules/fable-library.4.1.4/List.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.4.1.4/MapUtil.js";
import { Option, h1 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";

export function aspectRatio(percent, element) {
    let arg;
    const props = [["style", {
        position: "relative",
        width: "100%",
        height: "0",
        paddingBottom: (arg = (~~((1 / percent) * 100) | 0), toText(printf("%d%%"))(arg)),
    }]];
    const children = [element(ofArray([new CSSProp(291, ["absolute"]), new CSSProp(395, ["100%"]), new CSSProp(189, ["100%"]), new CSSProp(208, ["0"]), new CSSProp(365, ["0"])]))];
    return react.createElement("div", keyValueList(props, 1), ...children);
}

export const root = ofArray([h1(singleton(new Option(2, [])))(singleton("Lageplan")), aspectRatio(16 / 9, (styles) => {
    const props = [new HTMLAttr(149, ["https://www.google.com/maps/embed?pb=!1m17!1m8!1m3!1d753.3551123114898!2d13.7883404!3d47.9512645!3m2!1i1024!2i768!4f13.1!4m6!3e0!4m3!3m2!1d47.9511462!2d13.788469699999998!4m0!5e1!3m2!1sen!2sat!4v1533618732198"]), new HTMLAttr(89, ["0"]), ["style", keyValueList(append(styles, singleton(new CSSProp(33, ["0"]))), 1)], ["allowFullScreen", void 0]];
    return react.createElement("iframe", keyValueList(props, 1));
})]);

