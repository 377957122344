import { oneOf, stringParam, s, top, map } from "./fable_modules/Fable.Elmish.UrlParser.1.0.1/parser.fs.js";
import { toString, toUrl, UeberDenHofMenuState, Page } from "./Global.fs.js";
import { some, defaultArgWith, map as map_1, defaultArg } from "./fable_modules/fable-library.4.1.4/Option.js";
import { singleton, ofArray, collect } from "./fable_modules/fable-library.4.1.4/List.js";
import { init as init_1, update as update_1 } from "./UeberDenHof/State.fs.js";
import { Msg } from "./UeberDenHof/Types.fs.js";
import { Message, Model } from "./Types.fs.js";
import { Cmd_batch, Cmd_none, Cmd_map } from "./fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Navigation_modifyUrl } from "./fable_modules/Fable.Elmish.Browser.4.0.1/navigation.fs.js";
import { printf, toText } from "./fable_modules/fable-library.4.1.4/String.js";
import { update as update_2, init as init_2 } from "./Angebote/State.fs.js";
import { update as update_3, init as init_3 } from "./Administration/State.fs.js";

export const pageParser = (() => {
    let parser, queryParser;
    const parsers = ofArray([map(new Page(0, []), top), map(new Page(1, []), s("angebote")), map((arg) => defaultArg(map_1((_arg) => (new Page(2, [new UeberDenHofMenuState(1, [])])), arg), new Page(2, [new UeberDenHofMenuState(0, [])])), (parser = s("ueber-den-hof"), (queryParser = stringParam("expand-all"), (state_1) => collect(queryParser, parser(state_1))))), map(new Page(3, []), s("lageplan")), map(new Page(4, []), s("administration"))]);
    return (state_3) => oneOf(parsers, state_3);
})();

export function urlUpdate(page, model) {
    let arg_1;
    const patternInput_1 = defaultArgWith(map_1((page_1) => {
        let matchResult;
        if (page_1.tag === 2) {
            if (page_1.fields[0].tag === 1) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0: {
                const patternInput = update_1(new Msg(2, []), model.UeberDenHof);
                return [new Model(page_1, model.Angebote, patternInput[0], model.Administration), Cmd_map((arg) => (new Message(2, [arg])), patternInput[1])];
            }
            default:
                return [new Model(page_1, model.Angebote, model.UeberDenHof, model.Administration), Cmd_none()];
        }
    }, page), () => {
        console.error(some("Error parsing url"));
        return [model, Navigation_modifyUrl(toUrl(model.CurrentPage))];
    });
    const model$0027 = patternInput_1[0];
    window.document.title = ((arg_1 = toString(model$0027.CurrentPage), toText(printf("%s | Enserhof z\'Ehrndorf"))(arg_1)));
    return [model$0027, patternInput_1[1]];
}

export function init(result) {
    const patternInput = init_1();
    const patternInput_1 = urlUpdate(result, new Model(new Page(1, []), init_2[0], patternInput[0], init_3[0]));
    return [patternInput_1[0], Cmd_batch(ofArray([patternInput_1[1], Cmd_map((arg) => (new Message(1, [arg])), init_2[1]), Cmd_map((arg_1) => (new Message(2, [arg_1])), patternInput[1]), Cmd_map((arg_2) => (new Message(3, [arg_2])), init_3[1])]))];
}

export function Navigation$0027_newUrl(url) {
    return singleton((_arg) => {
        history.pushState(void 0, "", url);
        const ev = new CustomEvent("NavigatedEvent");
        window.dispatchEvent(ev);
    });
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1: {
            const patternInput = update_2(msg.fields[0], model.Angebote);
            return [new Model(model.CurrentPage, patternInput[0], model.UeberDenHof, model.Administration), Cmd_map((arg) => (new Message(1, [arg])), patternInput[1])];
        }
        case 2: {
            const patternInput_1 = update_1(msg.fields[0], model.UeberDenHof);
            return [new Model(model.CurrentPage, model.Angebote, patternInput_1[0], model.Administration), Cmd_map((arg_1) => (new Message(2, [arg_1])), patternInput_1[1])];
        }
        case 3: {
            const patternInput_2 = update_3(msg.fields[0], model.Administration);
            return [new Model(model.CurrentPage, model.Angebote, model.UeberDenHof, patternInput_2[0]), Cmd_map((arg_2) => (new Message(3, [arg_2])), patternInput_2[1])];
        }
        default:
            return [model, Navigation$0027_newUrl(toUrl(msg.fields[0]))];
    }
}

