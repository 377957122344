import { Record, Union } from "../fable_modules/fable-library.4.1.4/Types.js";
import { record_type, class_type, makeUnion, getUnionCases, union_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { map, delay, toList } from "../fable_modules/fable-library.4.1.4/Seq.js";

export class MenuItem extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Esel", "Ponys", "Hasen", "Huehner", "Puma", "Ziegen", "Katzen"];
    }
}

export function MenuItem_$reflection() {
    return union_type("UeberDenHof.Types.MenuItem", [], MenuItem, () => [[], [], [], [], [], [], []]);
}

export const allMenuItems = (() => {
    const cases = getUnionCases(MenuItem_$reflection());
    return toList(delay(() => map((c) => makeUnion(c, []), cases)));
})();

export class Model extends Record {
    constructor(OpenMenus) {
        super();
        this.OpenMenus = OpenMenus;
    }
}

export function Model_$reflection() {
    return record_type("UeberDenHof.Types.Model", [], Model, () => [["OpenMenus", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [MenuItem_$reflection()])]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OpenMenu", "CloseMenu", "ExpandAllMenus"];
    }
}

export function Msg_$reflection() {
    return union_type("UeberDenHof.Types.Msg", [], Msg, () => [[["Item", MenuItem_$reflection()]], [["Item", MenuItem_$reflection()]], []]);
}

