import { parseParams, parse } from "../Fable.Elmish.UrlParser.1.0.1/parser.fs.js";
import { substring } from "../fable-library.4.1.4/String.js";

export function parsePath(parser, location) {
    return parse(parser, location.pathname, parseParams(location.search));
}

export function parseHash(parser, location) {
    let patternInput;
    const hash = (location.hash.length > 1) ? substring(location.hash, 1) : "";
    if (hash.indexOf("?") >= 0) {
        const h = substring(hash, 0, hash.indexOf("?"));
        patternInput = [h, substring(hash, h.length)];
    }
    else {
        patternInput = [hash, "?"];
    }
    return parse(parser, patternInput[0], parseParams(patternInput[1]));
}

