import { Union } from "../../fable-library.4.1.4/Types.js";
import { Common_parseOptions, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Color_ofColor, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Color_IColor_$reflection } from "../Common.fs.js";
import { union_type, list_type, class_type, string_type } from "../../fable-library.4.1.4/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.4.1.4/MapUtil.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-bold", "is-medium", "is-large", "is-halfheight", "is-fullheight-with-navbar", "is-fullheight", "Color", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Hero.Option", [], Option, () => [[], [], [], [], [], [], [["Item", Color_IColor_$reflection()]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <div class="hero"></div>
 */
export function hero(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 6:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            case 8:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 7:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 9:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "hero"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <div class="hero-head"></div>
 */
export function head(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "hero-head"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="hero-body"></div>
 */
export function body(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "hero-body"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="hero-foot"></div>
 */
export function foot(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "hero-foot"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="hero-video"></div>
 */
export function video(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "hero-video"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="hero-buttons"></div>
 */
export function buttons(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "hero-buttons"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

