import { Record, Union } from "../fable_modules/fable-library.4.1.4/Types.js";
import { record_type, list_type, string_type, union_type, class_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { string, datetimeUtc, field, map, oneOf } from "../fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.1.4/List.js";
import { datetime, object } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";

export class LoadStallzeitenError extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["HttpError"];
    }
}

export function LoadStallzeitenError_$reflection() {
    return union_type("Angebote.Types.LoadStallzeitenError", [], LoadStallzeitenError, () => [[["Item", class_type("System.Exception")]]]);
}

export class Stallzeit extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Timestamp", "InfoText"];
    }
}

export function Stallzeit_$reflection() {
    return union_type("Angebote.Types.Stallzeit", [], Stallzeit, () => [[["Item", class_type("System.DateTime")]], [["Item", string_type]]]);
}

export const StallzeitModule_decoder = (path_6) => ((value_6) => oneOf(ofArray([(path_2) => ((value_2) => map((arg) => (new Stallzeit(0, [arg])), (path_1, value_1) => field("timestamp", datetimeUtc, path_1, value_1), path_2, value_2)), (path_5) => ((value_5) => map((arg_1) => (new Stallzeit(1, [arg_1])), (path_4, value_4) => field("infotext", string, path_4, value_4), path_5, value_5))]), path_6, value_6));

export function StallzeitModule_encode(v) {
    if (v.tag === 1) {
        return object([["infotext", v.fields[0]]]);
    }
    else {
        return object([["timestamp", datetime(v.fields[0])]]);
    }
}

export class Stallzeiten extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Loading", "Loaded", "LoadError"];
    }
}

export function Stallzeiten_$reflection() {
    return union_type("Angebote.Types.Stallzeiten", [], Stallzeiten, () => [[], [["Item", list_type(Stallzeit_$reflection())]], [["Item", LoadStallzeitenError_$reflection()]]]);
}

export class Model extends Record {
    constructor(Stallzeiten) {
        super();
        this.Stallzeiten = Stallzeiten;
    }
}

export function Model_$reflection() {
    return record_type("Angebote.Types.Model", [], Model, () => [["Stallzeiten", Stallzeiten_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoadStallzeitenSuccess", "LoadStallzeitenError"];
    }
}

export function Msg_$reflection() {
    return union_type("Angebote.Types.Msg", [], Msg, () => [[["Item", list_type(Stallzeit_$reflection())]], [["Item", LoadStallzeitenError_$reflection()]]]);
}

