import { moment } from "./fable_modules/Fable.MomentJs.2.0.4.23973/MomentJs.fs.js";
import { Union } from "./fable_modules/fable-library.4.1.4/Types.js";
import { union_type } from "./fable_modules/fable-library.4.1.4/Reflection.js";

moment.locale("de");

export class UeberDenHofMenuState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OpenMenusExpanded", "AllMenusExpanded"];
    }
}

export function UeberDenHofMenuState_$reflection() {
    return union_type("Global.UeberDenHofMenuState", [], UeberDenHofMenuState, () => [[], []]);
}

export class Page extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Startseite", "Angebote", "UeberDenHof", "Lageplan", "Administration"];
    }
}

export function Page_$reflection() {
    return union_type("Global.Page", [], Page, () => [[], [], [["Item", UeberDenHofMenuState_$reflection()]], [], []]);
}

export function toUrl(page) {
    switch (page.tag) {
        case 1:
            return "/angebote";
        case 2:
            if (page.fields[0].tag === 1) {
                return "/ueber-den-hof?expand-all=1";
            }
            else {
                return "/ueber-den-hof";
            }
        case 3:
            return "/lageplan";
        case 4:
            return "/administration";
        default:
            return "/";
    }
}

export function toString(_arg) {
    let matchResult;
    switch (_arg.tag) {
        case 1: {
            matchResult = 1;
            break;
        }
        case 2: {
            if (_arg.fields[0].tag === 1) {
                matchResult = 2;
            }
            else {
                matchResult = 2;
            }
            break;
        }
        case 3: {
            matchResult = 3;
            break;
        }
        case 4: {
            matchResult = 4;
            break;
        }
        default:
            matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return "Startseite";
        case 1:
            return "Angebote";
        case 2:
            return "Über den Hof";
        case 3:
            return "Lageplan";
        default:
            return "Administration";
    }
}

