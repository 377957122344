import { Union } from "../../fable-library.4.1.4/Types.js";
import { list_type, class_type, string_type, union_type } from "../../fable-library.4.1.4/Reflection.js";
import { Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Reflection_getCaseName } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.4.1.4/MapUtil.js";
import { cons } from "../../fable-library.4.1.4/List.js";

export class ISize extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-1", "is-2", "is-3", "is-4", "is-5", "is-6", "is-7", "is-8", "is-9", "is-10", "is-11", "is-12"];
    }
}

export function ISize_$reflection() {
    return union_type("Fulma.Tile.ISize", [], ISize, () => [[], [], [], [], [], [], [], [], [], [], [], []]);
}

export function ISize_ToString_Z15E9EFF2(x) {
    return Reflection_getCaseName(x);
}

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Size", "CustomClass", "Props", "is-child", "is-ancestor", "is-parent", "is-vertical", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Tile.Option", [], Option, () => [[["Item", ISize_$reflection()]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [], [], [], [], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <div class="tile"></div>
 */
export function tile(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 3:
            case 4:
            case 5:
            case 6:
                return Common_GenericOptions__AddCaseName_1505(result, option);
            case 2:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 1:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 7:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddClass_Z721C83C5(result, ISize_ToString_Z15E9EFF2(option.fields[0]));
        }
    }, "tile"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <div class="tile is-parent"></div>
 */
export function parent(options, children) {
    return tile(cons(new Option(5, []), options), children);
}

/**
 * Generate <div class="tile is-child"></div>
 */
export function child(options, children) {
    return tile(cons(new Option(3, []), options), children);
}

/**
 * Generate <div class="tile is-ancestor"></div>
 */
export function ancestor(options, children) {
    return tile(cons(new Option(4, []), options), children);
}

