import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.1.4/Seq.js";
import { empty, ofArray, singleton, map } from "../fable_modules/fable-library.4.1.4/List.js";
import { Option, div } from "../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { Option as Option_1, p } from "../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { input } from "../fable_modules/Fulma.3.0.0/Elements/Form/./Input.fs.js";
import { Option as Option_2, IInputType } from "../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { printf, toText, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.1.4/String.js";
import { Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { StallzeitTimestampValue, Msg, StallzeitValue } from "./Types.fs.js";
import { Option as Option_3, icon } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Option as Option_4, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { moment } from "../fable_modules/Fable.MomentJs.2.0.4.23973/MomentJs.fs.js";
import { HTMLAttr } from "../fable_modules/Fable.React.9.3.0/Fable.React.Props.fs.js";
import { Option as Option_5, notification } from "../fable_modules/Fulma.3.0.0/Elements/Notification.fs.js";
import { h2, Option as Option_6, h1 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";

export function root(model, dispatch) {
    const stallzeitenForm = toList(delay(() => append(map((stallzeit) => {
        const matchValue_1 = stallzeit.Value;
        if (matchValue_1.tag === 1) {
            const fieldId_1 = stallzeit.Id;
            const text = matchValue_1.fields[0];
            return div(singleton(new Option(0, [])), ofArray([p(singleton(new Option_1(1, [])), ofArray([input(ofArray([new Option_2(1, [new IInputType(0, [])]), new Option_2(8, [text]), new Option_2(2, [isNullOrWhiteSpace(text) ? (new Color_IColor(8, [])) : (new Color_IColor(6, []))]), new Option_2(13, [(evt_1) => {
                dispatch(new Msg(5, [[fieldId_1, new StallzeitValue(1, [evt_1.target.value])]]));
            }])])), icon(singleton(new Option_3(1, [])), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-info"])), [])))])), p(empty(), singleton(button(ofArray([new Option_4(0, [new Color_IColor(8, [])]), new Option_4(18, [(_evt_1) => {
                dispatch(new Msg(8, [fieldId_1]));
            }])]), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-trash"])), [])))))))]));
        }
        else {
            const fieldId = stallzeit.Id;
            const time = matchValue_1.fields[0];
            let patternInput;
            if (time.tag === 0) {
                patternInput = [time.fields[0], new Color_IColor(8, [])];
            }
            else {
                const momentTime = moment(time.fields[0]);
                patternInput = [momentTime.format("YYYY-MM-DDTHH:mm"), new Color_IColor(6, [])];
            }
            return div(singleton(new Option(0, [])), ofArray([p(singleton(new Option_1(1, [])), ofArray([input(ofArray([new Option_2(1, [new IInputType(2, [])]), new Option_2(8, [patternInput[0]]), new Option_2(2, [patternInput[1]]), new Option_2(13, [(evt) => {
                let matchValue;
                dispatch(new Msg(5, [[fieldId, new StallzeitValue(0, [(matchValue = (moment((evt.target.value), "YYYY-MM-DDTHH:mm", true)), matchValue.isValid() ? (new StallzeitTimestampValue(1, [matchValue.toDate()])) : (new StallzeitTimestampValue(0, [evt.target.value])))])]]));
            }])])), icon(singleton(new Option_3(1, [])), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-calendar"])), [])))])), p(empty(), singleton(button(ofArray([new Option_4(0, [new Color_IColor(8, [])]), new Option_4(18, [(_evt) => {
                dispatch(new Msg(8, [fieldId]));
            }])]), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-trash"])), [])))))))]));
        }
    }, model.LocalStallzeiten), delay(() => singleton_1(div(singleton(new Option(4, [])), ofArray([p(empty(), singleton(button(ofArray([new Option_4(0, [new Color_IColor(6, [])]), new Option_4(18, [(_evt_2) => {
        dispatch(new Msg(6, []));
    }]), new Option_4(17, [singleton(new HTMLAttr(158, ["Zeitpunkt hinzufügen"]))])]), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-calendar-plus"])), []))))))), p(empty(), singleton(button(ofArray([new Option_4(0, [new Color_IColor(6, [])]), new Option_4(18, [(_evt_3) => {
        dispatch(new Msg(7, []));
    }]), new Option_4(17, [singleton(new HTMLAttr(158, ["Infotext hinzufügen"]))])]), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-file"])), []))))))), p(empty(), singleton(button(ofArray([new Option_4(0, [new Color_IColor(6, [])]), new Option_4(18, [(_evt_4) => {
        dispatch(new Msg(9, []));
    }])]), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-save"])), [])))))))])))))));
    let existingStallzeiten;
    const matchValue_2 = model.RemoteStallzeiten;
    existingStallzeiten = ((matchValue_2.tag === 1) ? singleton(icon(empty(), singleton(Fa_i(ofArray([new Fa_IconOption(11, ["fas fa-spinner"]), new Fa_IconOption(12, [])]), [])))) : ((matchValue_2.tag === 2) ? stallzeitenForm : ((matchValue_2.tag === 3) ? ((matchValue_2.fields[0].tag === 1) ? toList(delay(() => append(singleton_1(notification(singleton(new Option_5(0, [new Color_IColor(8, [])])), singleton(toText(printf("Fehler beim Parsen der Stallzeiten."))))), delay(() => stallzeitenForm)))) : toList(delay(() => append(singleton_1(notification(singleton(new Option_5(0, [new Color_IColor(8, [])])), singleton(toText(printf("Fehler beim Laden der Stallzeiten."))))), delay(() => stallzeitenForm))))) : ofArray([div(empty(), singleton(p(singleton(new Option_1(1, [])), ofArray([input(ofArray([new Option_2(1, [new IInputType(0, [])]), new Option_2(12, ["GitHub Access Token"]), new Option_2(13, [(evt_2) => {
        dispatch(new Msg(3, [evt_2.target.value]));
    }])])), icon(singleton(new Option_3(1, [])), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-lock"])), [])))])))), div(empty(), singleton(p(empty(), singleton(button(ofArray([new Option_4(0, [new Color_IColor(6, [])]), new Option_4(18, [(_evt_5) => {
        dispatch(new Msg(4, []));
    }])]), singleton("Login"))))))]))));
    return toList(delay(() => append(singleton_1(h1(singleton(new Option_6(2, [])))(singleton("Administration"))), delay(() => append(singleton_1(h2(singleton(new Option_6(3, [])))(singleton("Stallzeiten aktualisieren"))), delay(() => existingStallzeiten))))));
}

