import { content } from "../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import { ofArray, singleton, empty } from "../fable_modules/fable-library.4.1.4/List.js";
import { Option, notification } from "../fable_modules/Fulma.3.0.0/Elements/Notification.fs.js";
import { Modifier_IModifier, TextAlignment_Option, Screen, Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import * as hof from "../../images/startseite/hof.jpg";
import { HTMLAttr } from "../fable_modules/Fable.React.9.3.0/Fable.React.Props.fs.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.4.1.4/MapUtil.js";

export const root = singleton(content(empty(), ofArray([notification(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(4, [singleton(new Modifier_IModifier(5, [new Screen(0, []), new TextAlignment_Option(0, [])]))])]), singleton((() => {
    const s = "Du liebst Tiere und genießt es, sie zu beobachten und Zeit mit ihnen zu verbringen? Dann bist du bei uns genau richtig!";
    return s;
})())), (() => {
    let props;
    const children = [(props = [new HTMLAttr(149, [hof.default])], react.createElement("img", keyValueList(props, 1)))];
    return react.createElement("p", {}, ...children);
})(), (() => {
    let s_1;
    const children_2 = [(s_1 = "Wir, Johannes und Sylvia, leben mit unseren drei Kindern auf dem Enserhof in Ehrendorf bei Ohlsdorf - ca. 5 Autominuten von Gmunden entfernt.", s_1)];
    return react.createElement("p", {}, ...children_2);
})(), (() => {
    let s_3;
    const children_4 = ["Im Laufe der Jahre haben sich immer mehr verschiedene Tiere auf dem Hof eingelebt. ", (s_3 = "Es ist uns eine Herzensangelegenheit, dass unsere Tiere einen nahen Kontakt zu Menschen haben, dass sie ihren grundlegenden Bedürfnissen nachkommen können und dass sie bei uns auf dem Hof genug Platz zum Bewegen haben.", s_3)];
    return react.createElement("p", {}, ...children_4);
})()])));

