import * as main from "../../sass/main.sass";
import { toString, toUrl, UeberDenHofMenuState, Page } from "./Global.fs.js";
import { empty, singleton, append, ofArray } from "./fable_modules/fable-library.4.1.4/List.js";
import { equals } from "./fable_modules/fable-library.4.1.4/Util.js";
import { body, hero } from "./fable_modules/Fulma.3.0.0/Layouts/Hero.fs.js";
import { Option, container } from "./fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { h1, Option as Option_1, h2 } from "./fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { Modifier_IModifier, Color_IColor } from "./fable_modules/Fulma.3.0.0/Common.fs.js";
import { Item_Option, Item_a, Option as Option_2, navbar } from "./fable_modules/Fulma.3.0.0/Components/Navbar.fs.js";
import { map, delay, toList } from "./fable_modules/fable-library.4.1.4/Seq.js";
import { DOMAttr, HTMLAttr } from "./fable_modules/Fable.React.9.3.0/Fable.React.Props.fs.js";
import { Message } from "./Types.fs.js";
import { Option as Option_3, section } from "./fable_modules/Fulma.3.0.0/Layouts/Section.fs.js";
import { root as root_1 } from "./Angebote/View.fs.js";
import { root as root_2 } from "./UeberDenHof/View.fs.js";
import { root as root_3 } from "./Lageplan/View.fs.js";
import { root as root_4 } from "./Administration/View.fs.js";
import { root as root_5 } from "./Startseite/View.fs.js";
import * as react from "react";
import { ProgramModule_run } from "./fable_modules/Fable.Elmish.HMR.7.0.0/../Fable.Elmish.4.0.2/program.fs.js";
import { Program_withReactBatched } from "./fable_modules/Fable.Elmish.HMR.7.0.0/../Fable.Elmish.React.4.0.0/react.fs.js";
import { ProgramModule_toNavigable } from "./fable_modules/Fable.Elmish.Browser.4.0.1/navigation.fs.js";
import { parsePath } from "./fable_modules/Fable.Elmish.Browser.4.0.1/parser.fs.js";
import { update, init, urlUpdate, pageParser } from "./State.fs.js";
import { ProgramModule_mkProgram } from "./fable_modules/Fable.Elmish.4.0.2/program.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { some } from "./fable_modules/fable-library.4.1.4/Option.js";


export function root(model, dispatch) {
    let _arg;
    const hasGitHubAccessToken = model.Administration.GitHubAccessToken !== "";
    let pages_1;
    const publicPages = ofArray([new Page(0, []), new Page(1, []), new Page(2, [new UeberDenHofMenuState(0, [])]), new Page(3, [])]);
    pages_1 = ((equals(model.CurrentPage, new Page(4, [])) ? true : hasGitHubAccessToken) ? append(publicPages, singleton(new Page(4, []))) : publicPages);
    const children = [hero(empty(), singleton(body(empty(), singleton(container(singleton(new Option(0, [])), ofArray([h2(ofArray([new Option_1(3, []), new Option_1(6, []), new Option_1(10, [singleton(new Modifier_IModifier(1, [new Color_IColor(2, [])]))])]))(singleton("Herzlich Willkommen am")), h1(singleton(new Option_1(10, [singleton(new Modifier_IModifier(1, [new Color_IColor(3, [])]))])))(singleton("Enserhof z\'Ehrndorf"))])))))), ((currentPage_1) => navbar(singleton(new Option_2(0, [new Color_IColor(2, [])])), toList(delay(() => map((page_1) => {
        const page = page_1;
        return Item_a(ofArray([new Item_Option(1, [equals(page, currentPage_1)]), new Item_Option(5, [ofArray([new HTMLAttr(94, [toUrl(page)]), new DOMAttr(40, [(e) => {
            e.preventDefault();
            dispatch(new Message(0, [page]));
        }])])])]), singleton(toString(page)));
    }, pages_1)))))(model.CurrentPage), section(singleton(new Option_3(1, ["main-section"])), singleton(container(empty(), (_arg = model.CurrentPage, (_arg.tag === 1) ? root_1(model.Angebote) : ((_arg.tag === 2) ? root_2(model.UeberDenHof, (arg_1) => {
        dispatch(new Message(2, [arg_1]));
    }) : ((_arg.tag === 3) ? root_3 : ((_arg.tag === 4) ? root_4(model.Administration, (arg_3) => {
        dispatch(new Message(3, [arg_3]));
    }) : root_5)))))))];
    return react.createElement("div", {}, ...children);
}

ProgramModule_run(Program_withReactBatched("elmish-app", ProgramModule_toNavigable((location) => parsePath(pageParser, location), urlUpdate, ProgramModule_mkProgram(init, update, root))));

(function () {
    const matchValue = navigator.serviceWorker;
    if (matchValue == null) {
    }
    else {
        const serviceWorker = matchValue;
        window.addEventListener("load", (_evt) => {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Delay_62FBFDE1(promise, () => (serviceWorker.register("sw.js").then((_arg) => {
                console.log(some("Service Worker is registered"), _arg);
                return Promise.resolve();
            }))).catch((_arg_1) => {
                console.error(some("ServiceWorker registration failed"), _arg_1);
                return Promise.resolve();
            }))));
            void pr;
        });
    }
})();

